/*Pickers Styles*/
.photoshop-picker,
.swatches-picker,
.alpha-picker,
.hue-picker {
	width: 100% !important;
}

.@{class-prefix}-alpha-pickers {
	position: relative;
	min-height: 250px;
}

.ant-calendar-footer .ant-calendar-ok-btn {
	margin-bottom: 0;
}
