@import '~antd/dist/antd.less';

body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
		'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}


.highlight-booking{
	background-color:#f6f6f6 !important;
	
}
.highlight-firstBooking{
	background-color: #e8fff4 !important;	

}

.ant-table-tbody > tr.ant-table-row:hover > td {
	background: none !important;
}

.editor-container {
	height: 100px !important;
  }

  /* Underline text on hover */
  .notificationMessage  .message{
	color:#f96d38;
  }

  .notificationMessage .description:hover {
	cursor: pointer;
	text-decoration: underline;
  }
@primary-color: #F96D38;