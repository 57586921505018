/*Testimonial Styles*/

.@{class-prefix}-testimonial {
	margin-bottom: @gx-card-margin-base;

	@media screen and (max-width: @screen-xs-max) {
		margin-bottom: @gx-card-margin-base-res;
	}
}

// Testimonials Classic
.@{class-prefix}-classic-testimonial {
	& .ant-avatar {
		margin: 0 auto;
		margin-bottom: 30px;
		width: @size-120;
		height: @size-120;

		& img {
			width: 100%;
			height: 100%;
		}

		@media screen and (max-width: @screen-xs-max) {
			width: @size-80;
			height: @size-80;
			margin-bottom: 15px;
		}
	}
}

// Testimonials Standard
.@{class-prefix}-testimonial-bg {
	background-color: fade(@black-color, 80%);
	padding: 30px;
	overflow: hidden;
	position: relative;
	.border-radius(@border-radius-base);

	@media screen and (max-width: @screen-xs-max) {
		padding: 16px;
	}

	& .ant-avatar {
		display: inline-block;
		width: @size-80;
		height: @size-80;
		overflow: hidden;
		border: 4px solid @primary-color;

		& img {
			width: 100%;
			height: 100%;
		}

		@media screen and (max-width: 450px) {
			width: @size-50;
			height: @size-50;
		}
	}

	& .@{class-prefix}-description {
		color: @grey-5;
		font-style: italic;
		font-weight: @font-weight-normal;
		line-height: 25px;
		margin-bottom: 15px;
	}

	& .@{class-prefix}-title {
		color: @primary-color;
		margin-bottom: 2px;
	}
}

.@{class-prefix}-testimonial-des {
	position: relative;
	padding-left: 30px;

	&:before {
		font-family: 'gaxon';
		font-size: 20px;
		content: '\e066';
		color: @white-color;
		position: absolute;
		top: 0;
		left: 0;
	}
}

.@{class-prefix}-standard {
	& .@{class-prefix}-media {
		@media screen and (max-width: (@screen-xs-max - 100px)) {
			flex-direction: column;
		}
	}
}
