/*Chart Styles*/
.recharts-default-tooltip {
	.border-radius(@border-radius-sm);
	background-color: fade(@white-color, 80%) !important;
	color: @secondary-color;
}

.recharts-tooltip-item-list li {
	color: @primary-color !important;
}
