/*Error Styles*/

.@{class-prefix}-page-error-container {
	position: relative;
	height: 100%;

	.flex-display(flex, row, wrap);
	.align-items(center);
	.justify-content(center);
	.flex-only(1);
}

.@{class-prefix}-page-error-content {
	margin: 0 auto;
	width: 380px;
	max-width: 94%;

	& h2 {
		margin-bottom: @size-50;

		@media screen and (max-width: @screen-sm-max) {
			margin-bottom: 20px;
		}
	}
}

.@{class-prefix}-error-code {
	color: @grey-8;
	font-size: 160px;
	text-align: center;
	line-height: 1;
	font-weight: @font-weight-medium;
	text-shadow: 10px 6px 8px rgba(117, 117, 117, 0.8);

	@media screen and (max-width: @screen-md-max) {
		font-size: 130px;
	}

	@media screen and (max-width: @screen-sm-max) {
		font-size: 100px;
	}

	@media screen and (max-width: @screen-xs-max) {
		font-size: 80px;
	}
}
