/*Editor Styles*/
.rdw-editor-toolbar {
	& .rdw-inline-wrapper {
		flex-wrap: wrap;

		& .rdw-option-wrapper {
			@media screen and (max-width: (@screen-xs-max - 176px)) {
				margin-bottom: 8px;
			}
		}
	}
}
