/* Footer Style */
.ant-layout-footer {
	border-top: @border-style-base @border-width-base @border-color;

	@media screen and (max-width: @screen-xs-max) {
		padding: @layout-footer-padding-res;
	}

	.@{class-prefix}-container-wrap & {
		padding-left: 0;
		padding-right: 0;
	}
}

.@{class-prefix}-layout-footer-content {
	.@{class-prefix}-container-wrap & {
		width: 94vw;
		margin-left: auto;
		margin-right: auto;
		padding-left: 16px;
		padding-right: 16px;

		@media screen and (min-width: @screen-md-min) {
			width: (@screen-md-min - 48); //720
		}

		@media screen and (min-width: @screen-lg-min) {
			width: (@screen-lg-min - 72); //920
		}

		@media screen and (min-width: @screen-xl-min) {
			width: (@screen-xl-min - 100); //1100
		}

		@media screen and (min-width: (@screen-xl-min + 167px)) {
			width: @screen-xl-min; //1200
		}

		@media screen and (min-width: @screen-xxl-min) {
			width: (@screen-xxl-min - 200); //1400
		}
	}
}
