/* Calendar Apps Style */
.@{class-prefix}-rbc-calendar {
	overflow: hidden;
	padding-bottom: @gx-card-margin-base;
	.flex-display(flex, column, nowrap);

	@media screen and (max-width: @screen-xs-max) {
		margin-bottom: @gx-card-margin-base-res;
	}
}

.rbc-calendar {
	.@{class-prefix}-rbc-calendar & {
		.flex-display(flex, column, nowrap);
		.flex(1);
		height: 100%;
	}
}

.rbc-event {
	background-color: @primary-color;
}

.rbc-event.rbc-selected {
	background-color: @primary-7;
}

.rbc-slot-selection {
	background-color: @primary-3;
}

.rbc-toolbar button:active,
.rbc-toolbar button.rbc-active {
	color: @white-color;
	background-image: none;
	.box-shadow(none);
	background-color: @primary-4;
	border-color: @primary-color;
}

.rbc-toolbar button:active:hover,
.rbc-toolbar button.rbc-active:hover,
.rbc-toolbar button:active:focus,
.rbc-toolbar button.rbc-active:focus,
.rbc-toolbar button:focus,
.rbc-toolbar button:hover {
	color: @white-color;
	background-color: @primary-4;
	border-color: @primary-color;
}

.rbc-month-row {
	min-height: 100px;
}

.rbc-event {
	min-height: 50px;
}

.rbc-btn-group {
	& button {
		cursor: pointer;
	}
}

@media screen and (max-width: 867px) {
	.rbc-toolbar {
		.flex-display(flex, column, nowrap);
		.align-items(flex-start);
	}

	.rbc-toolbar .rbc-toolbar-label {
		margin: 8px 0;
	}
}

@media screen and (max-width: 467px) {
	.rbc-toolbar {
		font-size: 12px;
	}

	.rbc-toolbar button {
		padding: 5px 10px;
	}
}

/* Calendar Components Style */
.@{class-prefix}-com-calendar {
	& .ant-fullcalendar-header {
		& .ant-select {
			margin-left: 8px;
			margin-bottom: 8px;
		}
	}

	& .ant-picker-calendar-header {
		flex-wrap: wrap;

		& .ant-select {
			margin-bottom: 8px;
		}
	}

	@media screen and (max-width: 349px) {
		margin: 0 -20px;
	}

	& .ant-select .ant-select-arrow {
		top: 50%;
	}
}

.@{class-prefix}-com-calendar-card {
	border: @border-style-base @border-width-base @border-color;
	max-width: 300px;
	.border-radius(@border-radius-sm);

	.@{class-prefix}-com-calendar {
		@media screen and (max-width: 349px) {
			margin: 0;
		}
	}
}
