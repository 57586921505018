/*flex Styles*/
.@{class-prefix}-d-flex {
	display: flex !important;
}

.@{class-prefix}-d-none {
	display: none !important;
}

.@{class-prefix}-d-inline-block {
	display: inline-block !important;
}

.@{class-prefix}-d-block {
	display: block !important;
}

.@{class-prefix}-d-inline-flex {
	display: inline-flex !important;
}

.@{class-prefix}-flex-1 {
	.flex(1);
}

/*Display Flex Classes*/
.@{class-prefix}-flex-row {
	.flex-display(flex, row, wrap) !important;
}

.@{class-prefix}-flex-column {
	.flex-display(flex, column, nowrap) !important;
}

.@{class-prefix}-flex-nowrap {
	flex-wrap: nowrap !important;
}

.@{class-prefix}-flex-row-reverse {
	flex-direction: row-reverse !important;
}

.@{class-prefix}-flex-column-reverse {
	flex-direction: column-reverse !important;
}

.@{class-prefix}-justify-content-start {
	.justify-content(flex-start) !important;
}

.@{class-prefix}-justify-content-end {
	.justify-content(flex-end) !important;
}

.@{class-prefix}-justify-content-center {
	.justify-content(center) !important;
}

.@{class-prefix}-justify-content-between {
	.justify-content(space-between) !important;
}

.@{class-prefix}-justify-content-around {
	.justify-content(space-around) !important;
}

.@{class-prefix}-align-items-start {
	.align-items(flex-start) !important;
}

.@{class-prefix}-align-items-end {
	.align-items(flex-end) !important;
}

.@{class-prefix}-align-items-center {
	.align-items(center) !important;
}

.@{class-prefix}-align-items-stretch {
	.align-items(stretch) !important;
}

.@{class-prefix}-align-content-start {
	.align-content(flex-start) !important;
}

.@{class-prefix}-align-content-end {
	.align-content(flex-end) !important;
}

.@{class-prefix}-align-content-center {
	.align-content(center) !important;
}

.@{class-prefix}-align-content-between {
	.align-content(space-between) !important;
}

.@{class-prefix}-align-content-around {
	.align-content(space-around) !important;
}

.@{class-prefix}-align-content-stretch {
	.align-content(stretch) !important;
}

.@{class-prefix}-align-self-auto {
	.align-self(auto) !important;
}

.@{class-prefix}-align-self-start {
	.align-self(flex-start) !important;
}

.@{class-prefix}-align-self-end {
	.align-self(flex-end) !important;
}

.@{class-prefix}-align-self-center {
	.align-self(center) !important;
}

.@{class-prefix}-align-self-baseline {
	.align-self(baseline) !important;
}

.@{class-prefix}-align-self-stretch {
	.align-self(stretch) !important;
}

// Order Style
.@{class-prefix}-order-first {
	-ms-flex-order: -1;
	order: -1;
}

.@{class-prefix}-order-1 {
	-ms-flex-order: 1;
	order: 1;
}

.@{class-prefix}-order-2 {
	-ms-flex-order: 2;
	order: 2;
}

.@{class-prefix}-order-3 {
	-ms-flex-order: 3;
	order: 3;
}

.@{class-prefix}-order-4 {
	-ms-flex-order: 4;
	order: 4;
}

.@{class-prefix}-order-5 {
	-ms-flex-order: 5;
	order: 5;
}

.@{class-prefix}-order-6 {
	-ms-flex-order: 6;
	order: 6;
}

.@{class-prefix}-order-7 {
	-ms-flex-order: 7;
	order: 7;
}

.@{class-prefix}-order-8 {
	-ms-flex-order: 8;
	order: 8;
}

.@{class-prefix}-order-9 {
	-ms-flex-order: 9;
	order: 9;
}

.@{class-prefix}-order-10 {
	-ms-flex-order: 10;
	order: 10;
}

.@{class-prefix}-order-11 {
	-ms-flex-order: 11;
	order: 11;
}

.@{class-prefix}-order-12 {
	-ms-flex-order: 12;
	order: 12;
}

//Offset Style
.@{class-prefix}-offset-1 {
	margin-left: 8.333333%;
}

.@{class-prefix}-offset-2 {
	margin-left: 16.666667%;
}

.@{class-prefix}-offset-3 {
	margin-left: 25%;
}

.@{class-prefix}-offset-4 {
	margin-left: 33.333333%;
}

.@{class-prefix}-offset-5 {
	margin-left: 41.666667%;
}

.@{class-prefix}-offset-6 {
	margin-left: 50%;
}

.@{class-prefix}-offset-7 {
	margin-left: 58.333333%;
}

.@{class-prefix}-offset-8 {
	margin-left: 66.666667%;
}

.@{class-prefix}-offset-9 {
	margin-left: 75%;
}

.@{class-prefix}-offset-10 {
	margin-left: 83.333333%;
}

.@{class-prefix}-offset-11 {
	margin-left: 91.666667%;
}

@media screen and (min-width: @screen-sm-min) {
	.@{class-prefix}-d-sm-none {
		display: none !important;
	}

	.@{class-prefix}-d-sm-inline-block {
		display: inline-block !important;
	}

	.@{class-prefix}-d-sm-block {
		display: block !important;
	}

	.@{class-prefix}-d-sm-flex {
		display: flex !important;
	}

	.@{class-prefix}-d-sm-inline-flex {
		display: inline-flex !important;
	}

	.@{class-prefix}-flex-sm-row {
		flex-direction: row !important;
	}

	.@{class-prefix}-flex-sm-column {
		flex-direction: column !important;
	}

	.@{class-prefix}-flex-sm-row-reverse {
		flex-direction: row-reverse !important;
	}

	.@{class-prefix}-flex-sm-column-reverse {
		flex-direction: column-reverse !important;
	}

	.@{class-prefix}-flex-sm-wrap {
		flex-wrap: wrap !important;
	}

	.@{class-prefix}-flex-sm-nowrap {
		flex-wrap: nowrap !important;
	}

	.@{class-prefix}-flex-sm-wrap-reverse {
		flex-wrap: wrap-reverse !important;
	}

	.@{class-prefix}-justify-content-sm-start {
		justify-content: flex-start !important;
	}

	.@{class-prefix}-justify-content-sm-end {
		justify-content: flex-end !important;
	}

	.@{class-prefix}-justify-content-sm-center {
		justify-content: center !important;
	}

	.@{class-prefix}-justify-content-sm-between {
		justify-content: space-between !important;
	}

	.@{class-prefix}-justify-content-sm-around {
		justify-content: space-around !important;
	}

	.@{class-prefix}-align-items-sm-start {
		align-items: flex-start !important;
	}

	.@{class-prefix}-align-items-sm-end {
		align-items: flex-end !important;
	}

	.@{class-prefix}-align-items-sm-center {
		align-items: center !important;
	}

	.@{class-prefix}-align-items-sm-baseline {
		align-items: baseline !important;
	}

	.@{class-prefix}-align-items-sm-stretch {
		align-items: stretch !important;
	}

	.@{class-prefix}-align-content-sm-start {
		align-content: flex-start !important;
	}

	.@{class-prefix}-align-content-sm-end {
		align-content: flex-end !important;
	}

	.@{class-prefix}-align-content-sm-center {
		align-content: center !important;
	}

	.@{class-prefix}-align-content-sm-between {
		align-content: space-between !important;
	}

	.@{class-prefix}-align-content-sm-around {
		align-content: space-around !important;
	}

	.@{class-prefix}-align-content-sm-stretch {
		align-content: stretch !important;
	}

	.@{class-prefix}-align-self-sm-auto {
		align-self: auto !important;
	}

	.@{class-prefix}-align-self-sm-start {
		align-self: flex-start !important;
	}

	.@{class-prefix}-align-self-sm-end {
		align-self: flex-end !important;
	}

	.@{class-prefix}-align-self-sm-center {
		align-self: center !important;
	}

	.@{class-prefix}-align-self-sm-baseline {
		align-self: baseline !important;
	}

	.@{class-prefix}-align-self-sm-stretch {
		align-self: stretch !important;
	}

	.@{class-prefix}-order-sm-first {
		-ms-flex-order: -1;
		order: -1;
	}

	.@{class-prefix}-order-sm-1 {
		-ms-flex-order: 1;
		order: 1;
	}

	.@{class-prefix}-order-sm-2 {
		-ms-flex-order: 2;
		order: 2;
	}

	.@{class-prefix}-order-sm-3 {
		-ms-flex-order: 3;
		order: 3;
	}

	.@{class-prefix}-order-sm-4 {
		-ms-flex-order: 4;
		order: 4;
	}

	.@{class-prefix}-order-sm-5 {
		-ms-flex-order: 5;
		order: 5;
	}

	.@{class-prefix}-order-sm-6 {
		-ms-flex-order: 6;
		order: 6;
	}

	.@{class-prefix}-order-sm-7 {
		-ms-flex-order: 7;
		order: 7;
	}

	.@{class-prefix}-order-sm-8 {
		-ms-flex-order: 8;
		order: 8;
	}

	.@{class-prefix}-order-sm-9 {
		-ms-flex-order: 9;
		order: 9;
	}

	.@{class-prefix}-order-sm-10 {
		-ms-flex-order: 10;
		order: 10;
	}

	.@{class-prefix}-order-sm-11 {
		-ms-flex-order: 11;
		order: 11;
	}

	.@{class-prefix}-order-sm-12 {
		-ms-flex-order: 12;
		order: 12;
	}

	.@{class-prefix}-offset-sm-0 {
		margin-left: 0;
	}

	.@{class-prefix}-offset-sm-1 {
		margin-left: 8.333333%;
	}

	.@{class-prefix}-offset-sm-2 {
		margin-left: 16.666667%;
	}

	.@{class-prefix}-offset-sm-3 {
		margin-left: 25%;
	}

	.@{class-prefix}-offset-sm-4 {
		margin-left: 33.333333%;
	}

	.@{class-prefix}-offset-sm-5 {
		margin-left: 41.666667%;
	}

	.@{class-prefix}-offset-sm-6 {
		margin-left: 50%;
	}

	.@{class-prefix}-offset-sm-7 {
		margin-left: 58.333333%;
	}

	.@{class-prefix}-offset-sm-8 {
		margin-left: 66.666667%;
	}

	.@{class-prefix}-offset-sm-9 {
		margin-left: 75%;
	}

	.@{class-prefix}-offset-sm-10 {
		margin-left: 83.333333%;
	}

	.@{class-prefix}-offset-sm-11 {
		margin-left: 91.666667%;
	}
}

@media screen and (min-width: @screen-md-min) {
	.@{class-prefix}-d-md-none {
		display: none !important;
	}

	.@{class-prefix}-d-md-inline-block {
		display: inline-block !important;
	}

	.@{class-prefix}-d-md-block {
		display: block !important;
	}

	.@{class-prefix}-d-md-flex {
		display: flex !important;
	}

	.@{class-prefix}-d-md-inline-flex {
		display: inline-flex !important;
	}

	.@{class-prefix}-flex-md-row {
		flex-direction: row !important;
	}

	.@{class-prefix}-flex-md-column {
		flex-direction: column !important;
	}

	.@{class-prefix}-flex-md-row-reverse {
		flex-direction: row-reverse !important;
	}

	.@{class-prefix}-flex-md-column-reverse {
		flex-direction: column-reverse !important;
	}

	.@{class-prefix}-flex-md-wrap {
		flex-wrap: wrap !important;
	}

	.@{class-prefix}-flex-md-nowrap {
		flex-wrap: nowrap !important;
	}

	.@{class-prefix}-flex-md-wrap-reverse {
		flex-wrap: wrap-reverse !important;
	}

	.@{class-prefix}-justify-content-md-start {
		justify-content: flex-start !important;
	}

	.@{class-prefix}-justify-content-md-end {
		justify-content: flex-end !important;
	}

	.@{class-prefix}-justify-content-md-center {
		justify-content: center !important;
	}

	.@{class-prefix}-justify-content-md-between {
		justify-content: space-between !important;
	}

	.@{class-prefix}-justify-content-md-around {
		justify-content: space-around !important;
	}

	.@{class-prefix}-align-items-md-start {
		align-items: flex-start !important;
	}

	.@{class-prefix}-align-items-md-end {
		align-items: flex-end !important;
	}

	.@{class-prefix}-align-items-md-center {
		align-items: center !important;
	}

	.@{class-prefix}-align-items-md-baseline {
		align-items: baseline !important;
	}

	.@{class-prefix}-align-items-md-stretch {
		align-items: stretch !important;
	}

	.@{class-prefix}-align-content-md-start {
		align-content: flex-start !important;
	}

	.@{class-prefix}-align-content-md-end {
		align-content: flex-end !important;
	}

	.@{class-prefix}-align-content-md-center {
		align-content: center !important;
	}

	.@{class-prefix}-align-content-md-between {
		align-content: space-between !important;
	}

	.@{class-prefix}-align-content-md-around {
		align-content: space-around !important;
	}

	.@{class-prefix}-align-content-md-stretch {
		align-content: stretch !important;
	}

	.@{class-prefix}-align-self-md-auto {
		align-self: auto !important;
	}

	.@{class-prefix}-align-self-md-start {
		align-self: flex-start !important;
	}

	.@{class-prefix}-align-self-md-end {
		align-self: flex-end !important;
	}

	.@{class-prefix}-align-self-md-center {
		align-self: center !important;
	}

	.@{class-prefix}-align-self-md-baseline {
		align-self: baseline !important;
	}

	.@{class-prefix}-align-self-md-stretch {
		align-self: stretch !important;
	}

	.@{class-prefix}-order-md-first {
		-ms-flex-order: -1;
		order: -1;
	}

	.@{class-prefix}-order-md-1 {
		-ms-flex-order: 1;
		order: 1;
	}

	.@{class-prefix}-order-md-2 {
		-ms-flex-order: 2;
		order: 2;
	}

	.@{class-prefix}-order-md-3 {
		-ms-flex-order: 3;
		order: 3;
	}

	.@{class-prefix}-order-md-4 {
		-ms-flex-order: 4;
		order: 4;
	}

	.@{class-prefix}-order-md-5 {
		-ms-flex-order: 5;
		order: 5;
	}

	.@{class-prefix}-order-md-6 {
		-ms-flex-order: 6;
		order: 6;
	}

	.@{class-prefix}-order-md-7 {
		-ms-flex-order: 7;
		order: 7;
	}

	.@{class-prefix}-order-md-8 {
		-ms-flex-order: 8;
		order: 8;
	}

	.@{class-prefix}-order-md-9 {
		-ms-flex-order: 9;
		order: 9;
	}

	.@{class-prefix}-order-md-10 {
		-ms-flex-order: 10;
		order: 10;
	}

	.@{class-prefix}-order-md-11 {
		-ms-flex-order: 11;
		order: 11;
	}

	.@{class-prefix}-order-md-12 {
		-ms-flex-order: 12;
		order: 12;
	}

	.@{class-prefix}-offset-md-0 {
		margin-left: 0;
	}

	.@{class-prefix}-offset-md-1 {
		margin-left: 8.333333%;
	}

	.@{class-prefix}-offset-md-2 {
		margin-left: 16.666667%;
	}

	.@{class-prefix}-offset-md-3 {
		margin-left: 25%;
	}

	.@{class-prefix}-offset-md-4 {
		margin-left: 33.333333%;
	}

	.@{class-prefix}-offset-md-5 {
		margin-left: 41.666667%;
	}

	.@{class-prefix}-offset-md-6 {
		margin-left: 50%;
	}

	.@{class-prefix}-offset-md-7 {
		margin-left: 58.333333%;
	}

	.@{class-prefix}-offset-md-8 {
		margin-left: 66.666667%;
	}

	.@{class-prefix}-offset-md-9 {
		margin-left: 75%;
	}

	.@{class-prefix}-offset-md-10 {
		margin-left: 83.333333%;
	}

	.@{class-prefix}-offset-md-11 {
		margin-left: 91.666667%;
	}
}

@media screen and (min-width: @screen-lg-min) {
	.@{class-prefix}-d-lg-none {
		display: none !important;
	}

	.@{class-prefix}-d-lg-inline-block {
		display: inline-block !important;
	}

	.@{class-prefix}-d-lg-block {
		display: block !important;
	}

	.@{class-prefix}-d-lg-flex {
		display: flex !important;
	}

	.@{class-prefix}-d-lg-inline-flex {
		display: inline-flex !important;
	}

	.@{class-prefix}-flex-lg-row {
		flex-direction: row !important;
	}

	.@{class-prefix}-flex-lg-column {
		flex-direction: column !important;
	}

	.@{class-prefix}-flex-lg-row-reverse {
		flex-direction: row-reverse !important;
	}

	.@{class-prefix}-flex-lg-column-reverse {
		flex-direction: column-reverse !important;
	}

	.@{class-prefix}-flex-lg-wrap {
		flex-wrap: wrap !important;
	}

	.@{class-prefix}-flex-lg-nowrap {
		flex-wrap: nowrap !important;
	}

	.@{class-prefix}-flex-lg-wrap-reverse {
		flex-wrap: wrap-reverse !important;
	}

	.@{class-prefix}-justify-content-lg-start {
		justify-content: flex-start !important;
	}

	.@{class-prefix}-justify-content-lg-end {
		justify-content: flex-end !important;
	}

	.@{class-prefix}-justify-content-lg-center {
		justify-content: center !important;
	}

	.@{class-prefix}-justify-content-lg-between {
		justify-content: space-between !important;
	}

	.@{class-prefix}-justify-content-lg-around {
		justify-content: space-around !important;
	}

	.@{class-prefix}-align-items-lg-start {
		align-items: flex-start !important;
	}

	.@{class-prefix}-align-items-lg-end {
		align-items: flex-end !important;
	}

	.@{class-prefix}-align-items-lg-center {
		align-items: center !important;
	}

	.@{class-prefix}-align-items-lg-baseline {
		align-items: baseline !important;
	}

	.@{class-prefix}-align-items-lg-stretch {
		align-items: stretch !important;
	}

	.@{class-prefix}-align-content-lg-start {
		align-content: flex-start !important;
	}

	.@{class-prefix}-align-content-lg-end {
		align-content: flex-end !important;
	}

	.@{class-prefix}-align-content-lg-center {
		align-content: center !important;
	}

	.@{class-prefix}-align-content-lg-between {
		align-content: space-between !important;
	}

	.@{class-prefix}-align-content-lg-around {
		align-content: space-around !important;
	}

	.@{class-prefix}-align-content-lg-stretch {
		align-content: stretch !important;
	}

	.@{class-prefix}-align-self-lg-auto {
		align-self: auto !important;
	}

	.@{class-prefix}-align-self-lg-start {
		align-self: flex-start !important;
	}

	.@{class-prefix}-align-self-lg-end {
		align-self: flex-end !important;
	}

	.@{class-prefix}-align-self-lg-center {
		align-self: center !important;
	}

	.@{class-prefix}-align-self-lg-baseline {
		align-self: baseline !important;
	}

	.@{class-prefix}-align-self-lg-stretch {
		align-self: stretch !important;
	}

	.@{class-prefix}-order-lg-first {
		-ms-flex-order: -1;
		order: -1;
	}

	.@{class-prefix}-order-lg-1 {
		-ms-flex-order: 1;
		order: 1;
	}

	.@{class-prefix}-order-lg-2 {
		-ms-flex-order: 2;
		order: 2;
	}

	.@{class-prefix}-order-lg-3 {
		-ms-flex-order: 3;
		order: 3;
	}

	.@{class-prefix}-order-lg-4 {
		-ms-flex-order: 4;
		order: 4;
	}

	.@{class-prefix}-order-lg-5 {
		-ms-flex-order: 5;
		order: 5;
	}

	.@{class-prefix}-order-lg-6 {
		-ms-flex-order: 6;
		order: 6;
	}

	.@{class-prefix}-order-lg-7 {
		-ms-flex-order: 7;
		order: 7;
	}

	.@{class-prefix}-order-lg-8 {
		-ms-flex-order: 8;
		order: 8;
	}

	.@{class-prefix}-order-lg-9 {
		-ms-flex-order: 9;
		order: 9;
	}

	.@{class-prefix}-order-lg-10 {
		-ms-flex-order: 10;
		order: 10;
	}

	.@{class-prefix}-order-lg-11 {
		-ms-flex-order: 11;
		order: 11;
	}

	.@{class-prefix}-order-lg-12 {
		-ms-flex-order: 12;
		order: 12;
	}

	.@{class-prefix}-offset-lg-0 {
		margin-left: 0;
	}

	.@{class-prefix}-offset-lg-1 {
		margin-left: 8.333333%;
	}

	.@{class-prefix}-offset-lg-2 {
		margin-left: 16.666667%;
	}

	.@{class-prefix}-offset-lg-3 {
		margin-left: 25%;
	}

	.@{class-prefix}-offset-lg-4 {
		margin-left: 33.333333%;
	}

	.@{class-prefix}-offset-lg-5 {
		margin-left: 41.666667%;
	}

	.@{class-prefix}-offset-lg-6 {
		margin-left: 50%;
	}

	.@{class-prefix}-offset-lg-7 {
		margin-left: 58.333333%;
	}

	.@{class-prefix}-offset-lg-8 {
		margin-left: 66.666667%;
	}

	.@{class-prefix}-offset-lg-9 {
		margin-left: 75%;
	}

	.@{class-prefix}-offset-lg-10 {
		margin-left: 83.333333%;
	}

	.@{class-prefix}-offset-lg-11 {
		margin-left: 91.666667%;
	}
}

@media screen and (min-width: @screen-xl-min) {
	.@{class-prefix}-d-xl-none {
		display: none !important;
	}

	.@{class-prefix}-d-xl-inline-block {
		display: inline-block !important;
	}

	.@{class-prefix}-d-xl-block {
		display: block !important;
	}

	.@{class-prefix}-d-xl-flex {
		display: flex !important;
	}

	.@{class-prefix}-d-xl-inline-flex {
		display: inline-flex !important;
	}

	.@{class-prefix}-flex-xl-row {
		flex-direction: row !important;
	}

	.@{class-prefix}-flex-xl-column {
		flex-direction: column !important;
	}

	.@{class-prefix}-flex-xl-row-reverse {
		flex-direction: row-reverse !important;
	}

	.@{class-prefix}-flex-xl-column-reverse {
		flex-direction: column-reverse !important;
	}

	.@{class-prefix}-flex-xl-wrap {
		flex-wrap: wrap !important;
	}

	.@{class-prefix}-flex-xl-nowrap {
		flex-wrap: nowrap !important;
	}

	.@{class-prefix}-flex-xl-wrap-reverse {
		flex-wrap: wrap-reverse !important;
	}

	.@{class-prefix}-justify-content-xl-start {
		justify-content: flex-start !important;
	}

	.@{class-prefix}-justify-content-xl-end {
		justify-content: flex-end !important;
	}

	.@{class-prefix}-justify-content-xl-center {
		justify-content: center !important;
	}

	.justify-content-xl-between {
		justify-content: space-between !important;
	}

	.@{class-prefix}-justify-content-xl-around {
		justify-content: space-around !important;
	}

	.@{class-prefix}-align-items-xl-start {
		align-items: flex-start !important;
	}

	.@{class-prefix}-align-items-xl-end {
		align-items: flex-end !important;
	}

	.@{class-prefix}-align-items-xl-center {
		align-items: center !important;
	}

	.@{class-prefix}-align-items-xl-baseline {
		align-items: baseline !important;
	}

	.@{class-prefix}-align-items-xl-stretch {
		align-items: stretch !important;
	}

	.@{class-prefix}-align-content-xl-start {
		align-content: flex-start !important;
	}

	.@{class-prefix}-align-content-xl-end {
		align-content: flex-end !important;
	}

	.@{class-prefix}-align-content-xl-center {
		align-content: center !important;
	}

	.@{class-prefix}-align-content-xl-between {
		align-content: space-between !important;
	}

	.@{class-prefix}-align-content-xl-around {
		align-content: space-around !important;
	}

	.@{class-prefix}-align-content-xl-stretch {
		align-content: stretch !important;
	}

	.@{class-prefix}-align-self-xl-auto {
		align-self: auto !important;
	}

	.@{class-prefix}-align-self-xl-start {
		align-self: flex-start !important;
	}

	.@{class-prefix}-align-self-xl-end {
		align-self: flex-end !important;
	}

	.@{class-prefix}-align-self-xl-center {
		align-self: center !important;
	}

	.@{class-prefix}-align-self-xl-baseline {
		align-self: baseline !important;
	}

	.@{class-prefix}-align-self-xl-stretch {
		align-self: stretch !important;
	}

	.@{class-prefix}-order-xl-first {
		-ms-flex-order: -1;
		order: -1;
	}

	.@{class-prefix}-order-xl-1 {
		-ms-flex-order: 1;
		order: 1;
	}

	.@{class-prefix}-order-xl-2 {
		-ms-flex-order: 2;
		order: 2;
	}

	.@{class-prefix}-order-xl-3 {
		-ms-flex-order: 3;
		order: 3;
	}

	.@{class-prefix}-order-xl-4 {
		-ms-flex-order: 4;
		order: 4;
	}

	.@{class-prefix}-order-xl-5 {
		-ms-flex-order: 5;
		order: 5;
	}

	.@{class-prefix}-order-xl-6 {
		-ms-flex-order: 6;
		order: 6;
	}

	.@{class-prefix}-order-xl-7 {
		-ms-flex-order: 7;
		order: 7;
	}

	.@{class-prefix}-order-xl-8 {
		-ms-flex-order: 8;
		order: 8;
	}

	.@{class-prefix}-order-xl-9 {
		-ms-flex-order: 9;
		order: 9;
	}

	.@{class-prefix}-order-xl-10 {
		-ms-flex-order: 10;
		order: 10;
	}

	.@{class-prefix}-order-xl-11 {
		-ms-flex-order: 11;
		order: 11;
	}

	.@{class-prefix}-order-xl-12 {
		-ms-flex-order: 12;
		order: 12;
	}

	.@{class-prefix}-offset-xl-0 {
		margin-left: 0;
	}

	.@{class-prefix}-offset-xl-1 {
		margin-left: 8.333333%;
	}

	.@{class-prefix}-offset-xl-2 {
		margin-left: 16.666667%;
	}

	.@{class-prefix}-offset-xl-3 {
		margin-left: 25%;
	}

	.@{class-prefix}-offset-xl-4 {
		margin-left: 33.333333%;
	}

	.@{class-prefix}-offset-xl-5 {
		margin-left: 41.666667%;
	}

	.@{class-prefix}-offset-xl-6 {
		margin-left: 50%;
	}

	.@{class-prefix}-offset-xl-7 {
		margin-left: 58.333333%;
	}

	.@{class-prefix}-offset-xl-8 {
		margin-left: 66.666667%;
	}

	.@{class-prefix}-offset-xl-9 {
		margin-left: 75%;
	}

	.@{class-prefix}-offset-xl-10 {
		margin-left: 83.333333%;
	}

	.@{class-prefix}-offset-xl-11 {
		margin-left: 91.666667%;
	}
}
